import {isFunction} from "@kubric/utils";
import {useEffect, useState} from "react";

const getMediaMatch = (query) => {
  if (typeof window !== "undefined") {
    return window.matchMedia(query);
  }
  return {
    matches: true,
  };
};

export const useMediaQuery = (query) => {
  const mediaMatch = getMediaMatch(query);
  const [matches, setMatches] = useState(true);
  useEffect(() => {
    if (isFunction(mediaMatch.addEventListener)) {
      setMatches(mediaMatch.matches);
      const handler = (e) => setMatches(e.matches);
      mediaMatch.addEventListener("change", handler);
      return () => mediaMatch.removeEventListener("change", handler);
    }
  }, [mediaMatch]);
  return matches;
};
